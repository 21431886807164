import React, { Component, Fragment } from 'react'
import SEO from '@/components/SEO/SEO'
import PageHeader from '@/components/PageHeader/PageHeader'
import SectionHeading from '@/components/SectionHeading/SectionHeading'
import PostThumb from '@/components/PostThumb/PostThumb'
import Slide from '@/components/Slide/Slide'
import { prepDataFromSuggestions } from '@/utils/'

export default class Thanks extends Component {
	render() {
		const { location } = this.props
		if (!location.state) return null

		const {
			state: { image, body, suggested: tmpSuggest, recommendContent = [] }
		} = location

		const suggested = tmpSuggest ? prepDataFromSuggestions(tmpSuggest) : []

		const feed = [...suggested, ...recommendContent]

		return (
			<Fragment>
				<SEO title="thanks" image={image.src} url={location.href} />
				<PageHeader title="thanks" image={image} body={body} />
				{!!feed.length && (
					<div className="wrapper py-3">
						<div className="flex flex-wrap">
							<div className="w-full mb-2 md:mb-0 md:w-5/24 md:mr-1/24">
								<SectionHeading title="recommended for you" />
							</div>
							<div className="w-full md:w-18/24">
								<Slide
									content={feed}
									slidesToShow={3}
									wrapperClassName="md:w-22/24 lg:w-auto lg:pr-3"
									render={item => <PostThumb {...item} />}
								/>
							</div>
						</div>
					</div>
				)}
			</Fragment>
		)
	}
}
